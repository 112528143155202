<template>
  <div class="business_home">
    <div
      class="header_wrapper"
    >
      <div
        class="header_wrapper_content"
      >
        <sys-header
          :isActiveIn="true"
          ref="sysHeader"
          :effect="scrollTop!==0?'light':'dark'"
        ></sys-header>
      </div>
    </div>
    <div class="article_main" @scroll="scrollFn">
      <div class="article_main_content">
        <!-- 关于赛赋 -->
        <div class="lb_content">
          <el-carousel :height="bannerHeight+'px'" :autoplay="true">
            <el-carousel-item v-for="item in urlList" :key="item.id">
              <img class="lb_bg" :src="item.url" alt="暂无图片">
              <div class="lb_article">
                <div class="lb_article_content animate__animated animate__bounceInDown">
                  <div class="tips">{{item.label}}</div>
                  <div class="tips_desc">{{item.desc}}</div>
                  <div class="tips_en">{{item.descEn}}</div>
                  <div class="division_line"></div>
                  <div class="btn_content" @click="goPageFn(item.modelName)">
                    <img src="./images/btn@more.png">
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- 关于赛赋 -->
        <div class="about_content">
          <div class="about_topic">
            <span class="about_content_sy">ABOUT US</span>
            <span class="about_content_info">关于赛赋</span>
          </div>
          <div class="about_main">
            <div class="about_left">
              <div class="statistic_info">
                <div class="statistic_image">
                  <img :src="aboutSf.descInfoImage" alt=""/>
                </div>
                <!-- <div class="statistic_item" v-for="item in aboutSf.descInfos" :key="item.label">
                  <div class="statistic_item_val">
                    {{item.value}}
                  </div>
                  <div class="statistic_item_label">
                    {{item.label}}
                  </div>
                </div> -->
              </div>
              <!-- <div class="about_title">
                {{aboutSf.title}}
              </div> -->
              <div class="about_info">
                {{aboutSf.info}}
              </div>
              <div class="about_btn" @click="goPageFn('companyIntroduction')">
                <span>了解更多</span>
                <i class="el-icon-right"></i>
              </div>
            </div>
            <div class="about_right">
              <img :src="aboutSf.pic">
            </div>
          </div>
        </div>
        <!-- 赛赋服务 -->
        <div class="serve_content">
          <!-- <img class="serve_content_bg" src="./images/service@bg.png"> -->
          <div class="serve_main">
            <div class="serve_main_content">
              <div class="serve_topic">
                <span class="serve_topic_sy">SERVICE</span>
                <div class="serve_topic_info">
                  <div>赛赋服务</div>
                  <div class="serve_btn" @click="goPageFn('ktywfx')">
                    <span>了解更多</span>
                    <i class="el-icon-right"></i>
                  </div>
                </div>
              </div>
              <div class="serve_article">
                <div class="serve_article_line" v-for="(line,idx) in serviceInfos" :key="idx">
                  <div class="serve_article_item" :class="[{
                    'serve_article_item_dark':item.isDark
                  }]" v-for="item in line" :key="item.icon" @click="goPageFn(item.moduleName)">
                    <img class="img_el" :src="item.icon">
                    <img class="img_el_active" :src="item.iconActive">

                    <div class="serve_article_item_label">
                      {{item.label}}
                    </div>
                    <div class="serve_article_item_value">
                      {{item.desc}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 新闻资讯 -->
        <div class="news_content">
          <div class="news_topic">
            <span class="news_content_sy">NEWS</span>
            <span class="news_content_info">
              <div>新闻资讯</div>
              <div class="news_btn" @click="goPageFn('gsgg')">
                <span>了解更多</span>
                <i class="el-icon-right"></i>
              </div>
            </span>
          </div>
          <div class="news_main">
            <el-carousel :height="'400px'" class="custom_lb" indicator-position="outside" :autoplay="false">
              <el-carousel-item v-for="item in newsPageCnt" :key="item">
                <div class="news_item_main">
                  <div class="news_item" v-for="itemNews in pageSize" :key="`${item}_${itemNews}`">
                    <template v-if="getNewExist(item,itemNews)">
                      <div class="news_item_content">
                        <div class="img_content" style="height:250px">
                          <img :src="getNewInfo(item,itemNews).imageUrl">
                          <div class="date_content">
                            {{ getNewInfo(item,itemNews).publishDate }}
                          </div>
                        </div>
                        <div class="article_content">
                          <div class="article_topic" :title="getNewInfo(item,itemNews).title">
                            {{ getNewInfo(item,itemNews).title }}
                          </div>
                          <div class="article_btn" @click="goPageFnParam('gsgg',getNewInfo(item,itemNews))">
                            <span>查看详情</span>
                            <i class="el-icon-right"></i>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <!-- 导航 -->
        <div class="link_content">
          <div class="link_item">
            <div class="link_topic">
              赛赋特色
            </div>
            <div class="link_topic_en">
              SAIFU FEATURES
            </div>
            <div class="link_btn" @click="goPageFn('sfts')">
              <span>查看详情</span>
              <i class="el-icon-right"></i>
            </div>
          </div>
          <div class="link_item">
            <div class="link_topic">
              联系我们
            </div>
            <div class="link_topic_en">
              CONTACT US
            </div>
            <div class="link_btn" @click="goPageFn('swhz')">
              <span>查看详情</span>
              <i class="el-icon-right"></i>
            </div>
          </div>
        </div>
        <page-footer></page-footer>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import SysHeader from "../common/sysHeader";
import PageFooter from "../common/pageFooter";
export default {
  name:"home",
  components: {
    SysHeader,
    PageFooter
  },
  // 定义属性
  data() {
    return {
      scrollTop:0,// 滚动条位置
      /** 轮播 */
      urlList:[],
      bannerHeight: 0,
      bannerHeightInner:0,
      screenWidth: 0,
      /** 关于赛赋 */
      aboutSf:{},
      /** 服务 */
      serviceInfos:[],
      /** 新闻资讯 */
      newsInfos:[],
      pageSize:3,
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    newsPageCnt(){
      let ys = this.newsInfos.length%this.pageSize;
      let zs = parseInt(this.newsInfos.length/this.pageSize);
      return ys?zs+1:zs;
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth
    this.bannerHeight = 819 / 1920 * this.screenWidth
    // this.bannerHeightInner = 400/1920 * this.screenWidth>400?400:400/1920 * this.screenWidth<350400?350:400/1920 * this.screenWidth
    // 窗口大小发生改变
    window.addEventListener("resize", function () {
      this.screenWidth = window.innerWidth
      this.bannerHeight = 819 / 1920 * this.screenWidth
      // this.bannerHeightInner = 400/1920 * this.screenWidth>400?400:400/1920 * this.screenWidth<350400?350:400/1920 * this.screenWidth
    })
    this.getNewList();
    this.getData();
  },
  // 方法集合
  methods: {
    // 获取页面数据
    getData(){
      let _this = this;
      _this.getDataLb();
      _this.getDataAboutSf();
      _this.getDataServiceInfos();
    },
    // 获取数据 - 轮播
    getDataLb(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=home&secondaryTitle=carousel`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"[]";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.urlList = configData.map(i=>{
            return Object.assign({},i,{
              url: `${window.PICSEVERURL}/${i.url}`
            })
          })
        }else{
          _this.$notify({
            title: '失败',
            message: "轮播图数据请求失败",
            type: 'error'
          });
        }
      });
    },
    // 获取数据 - 关于赛赋
    getDataAboutSf(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=home&secondaryTitle=aboutSf`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"{}";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.aboutSf = Object.assign({},configData,{
            pic: `${window.PICSEVERURL}/${configData.pic}`,
            descInfoImage: `${window.PICSEVERURL}/${configData.descInfoImage}`
          })
        }else{
          _this.$notify({
            title: '失败',
            message: "关于赛赋数据请求失败",
            type: 'error'
          });
        }
      });
    },
    // 获取数据 - 赛赋服务
    getDataServiceInfos(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=home&secondaryTitle=serviceInfos`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"[]";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.serviceInfos = configData.map(i=>{
            return i.map(j=>{
              return Object.assign({},j,{
                icon: `${window.PICSEVERURL}/${j.icon}`,
                iconActive: `${window.PICSEVERURL}/${j.iconActive}`
              })
            })
          })
        }else{
          _this.$notify({
            title: '失败',
            message: "赛赋服务数据请求失败",
            type: 'error'
          });
        }
      });
    },
    // 带参路由跳转
    goPageFnParam(name,params){
      let _this = this;
      _this.$router.push({ 
        name: name,
        params: params
      })
    },
    // 路由跳转
    goPageFn(name){
      let _this = this;
      _this.$router.push({ 
        name: name,
      })
    },
    async getNewList () {
      const { data } = await this.$http.get(`${window.SEVERURL}/safeglp/generator/news/list?currPage=1&limit=6`)
      this.newsInfos = data.page.list
    },
    /** 新闻资讯 */
    getNewExist(page,order){
      let currentPageNews =  this.newsInfos.filter((i,idx)=>{
        return idx>=(page-1)*this.pageSize&&idx<page*this.pageSize;
      })
      return order<=currentPageNews.length;
    },
    getNewInfo(page,order){
      let currentPageNews =  this.newsInfos.filter((i,idx)=>{
        return idx>=(page-1)*this.pageSize&&idx<page*this.pageSize;
      })
      return currentPageNews[order-1];
    },
    /** 服务 */
    // 获取子服务的背景图标
    getServiceItemIcon(icon){
      return require(`./images/serviceItem@${icon}.png`);
    },
    // 获取背景图片
    getBgHref(item){
      return require(`./images/bigBg_${item}.png`)
    },
    // 滚动方法
    scrollFn(){
      let _this = this;
      _this.scrollTop = _this.$refs.gridMainContent.scrollTop;
    },
    
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
/deep/ .el-carousel__button{
  opacity: 1;
}
/deep/ .el-carousel__indicator.is-active button{
  background: #0086D1;
}
/deep/ .el-carousel__indicator:hover button{
  opacity: 1;
}
/deep/ .el-carousel__arrow{
  border: 1px solid #FFFFFF;
  background-color: rgba(0, 0, 0, 0);
}
/deep/ .custom_lb{
  width: 100%;
  .el-carousel__button{
    opacity: 1;
  }
  .el-carousel__indicator.is-active button{
    background: #0086D1;
    border: 1px solid #0163B8;
  }
  .el-carousel__indicator:hover button{
    opacity: 1;
  }
  .el-carousel__arrow{
    border: 1px solid #FFFFFF;
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    pointer-events: none;
  }
  .el-carousel__button{
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background: #FFFFFF;
    border: 2px solid #BEBEBE;
  }
}
.business_home{
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .header_wrapper {
    width: 100%;
    height: 100px;
    z-index: 2000;
    .header_wrapper_content {
      width: 100%;
      height: 100px;
    }
  }
  .article_main{
    flex: 1;
    overflow: hidden;
    .article_main_content{
      width: 100%;
      height: 100%;
      overflow: auto;
      background: #F6F7F9;
      /* 滚动条样式 */
      &::-webkit-scrollbar {
        width: 4px; /*  设置纵轴（y轴）轴滚动条 */
        height: 4px; /*  设置横轴（x轴）轴滚动条 */
      }
      /* 滚动条滑块（里面小方块） */
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #1a406f;
      }
      /* 滚动条轨道 */
      &::-webkit-scrollbar-track {
        border-radius: 0;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.1);
      }
      .lb_content{
        width: 100%;
        position: relative;
        .lb_bg{
          width: 100%;
          height: inherit;
        }
        .lb_article{
          position: absolute;
          z-index: 2;
          left: 60px;
          right: 60px;
          top: 40px;
          bottom: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          .lb_article_content{
            min-width: 1200px;
            width: 1520px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .tips{
              font-family: PingFang SC, PingFang SC;
              font-weight: 600;
              font-size: 26px;
              color: #000000;
              line-height: 30px;
              margin-bottom: 16px;

            }
            .tips_desc{
              font-family: PingFang SC, PingFang SC;
              font-weight: 600;
              font-size: 48px;
              color: #000000;
              line-height: 56px;
              margin-bottom: 13px;
            }
            .tips_en{
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 24px;
              color: #666666;
              line-height: 28px;
              margin-bottom: 25px;
            }
            .division_line{
              width: 210px;
              height: 3px;
              background: #0163B8;
              margin-bottom: 48px;
            }
            .btn_content{
              cursor: pointer;
              img{
                height: 46px;
                width: auto;
              }
            }

          }
        }
      }
      .about_content{
        min-width: 1200px;
        max-width: 1520px;
        width: calc(100vw - 10px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        margin-top: 120px;
        margin-bottom: 0;
        padding: 0 4px;
        box-sizing: border-box;
        .about_topic{
          position: relative;
          margin-bottom: 70px;
          .about_content_sy{
            font-family: PingFang SC, PingFang SC;
            font-size: 42px;
            color: #E4E8F0;
            line-height: 46px;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.8;
            transform: translate(0,24px);
            letter-spacing: 2px;
          }
          .about_content_info{
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 42px;
            color: #222222;
            line-height: 46px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          }
        } 
        .about_main{
          display: flex;
          // align-items: center;
          .about_left{
            flex: 1;
            overflow: hidden;
            margin-right: 16px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .statistic_info{
              display: flex;
              justify-content: space-between;
              .statistic_image{
                width: 100%;
                height: 100%;
                img{
                  width: 100%;
                }
              }
              .statistic_item{
                flex: 1;
                overflow: hidden;
                margin-bottom: 35px;
                .statistic_item_val{
                  height: 58px;
                  line-height: 58px;
                  font-family: Archivo Black, Archivo Black;
                  font-size: 32px;
                  color: #0086D1;
                  margin-bottom: 2px;
                }
                .statistic_item_label{
                  height: 25px;
                  font-family: PingFang SC, PingFang SC;
                  font-weight: 400;
                  font-size: 16px;
                  color: #000000;
                  line-height: 25px;
                }
              }
            }
            .about_title{
              height: 36px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 600;
              font-size: 20px;
              color: #000000;
              line-height: 36px;
            }
            .about_info{
              width: 100%;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 18px;
              color: #333333;
              line-height: 29px;
              margin-bottom: 28px;
            }
            .about_btn{
              width: 129px;
              height: 38px;
              background: #0086D1;
              border-radius: 19px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #FFFFFF;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              i{
                margin-left: 10px;
              }
            }
          }
          .about_right{
            flex: 1;
            overflow: hidden;
            img{
              width: 100%;
            }
          }
        }
      }
      .serve_content{
        position: relative;
        height: 1000px;
        background: #FFFFFF;
        width: 100%;
        // .serve_content_bg{
        //   // min-height: 450px;
        // }
        .serve_main{
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          .serve_main_content{
            min-width: 1200px;
            max-width: 1520px;
            width: calc(100vw - 10px);
            height: 100%;
            padding: 80px 0;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            .serve_topic{
              position: relative;
              margin-bottom: 70px;
              .serve_topic_sy{
                font-family: PingFang SC, PingFang SC;
                font-size: 42px;
                color: #E4E8F0;
                line-height: 46px;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0.3;
                transform: translate(0,24px);
                letter-spacing: 2px;
              }
              .serve_topic_info{
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 42px;
                color: #222222;
                line-height: 46px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                display: flex;
                width: 100%;
                justify-content: space-between;
                .serve_btn{
                  width: 129px;
                  height: 38px;
                  background: #FFFFFF;
                  border-radius: 100px 100px 100px 100px;
                  border: 1px solid #0163B8;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-family: PingFang SC, PingFang SC;
                  font-weight: 500;
                  font-size: 14px;
                  color: #0163B8;
                  border-radius: 19px;
                  cursor: pointer;
                  i{
                    margin-left: 10px;
                  }
                }
              }
            } 
            .serve_article{
              flex: 1;
              display: flex;
              flex-direction: column;
              width: 100%;
              display: flex;
              flex-direction: column;
              .serve_article_line{
                flex: 1;
                margin-bottom: 34px;
                overflow: hidden;
                display: flex;
                &:last-child{
                  margin-bottom: 0;
                }
                .serve_article_item{
                  flex: 1;
                  overflow: hidden;
                  background: #F6F7F9;
                  border-radius: 4px 4px 4px 4px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  padding: 20px 39px;
                  margin-right: 29px;
                  justify-content: space-evenly;
                  cursor: pointer;
                  &:last-child{
                    margin-right: 0;
                  }
                  &:hover{
                    background: #0086D1;
                    box-shadow: 0px 24px 68px 0px rgba(59,77,129,0.08);
                    .serve_article_item_label{
                      color: #FFFFFF;
                      height: 40px;
                      margin-bottom: 20px;
                    }
                    .serve_article_item_value{
                      color: #FFFFFF;
                    }
                    img{
                      &.img_el{
                        display: none;
                      }
                      &.img_el_active{
                        display: unset;
                      }
                    }
                  }
                  img{
                    width: 90px;
                    height: 90px;
                    margin-bottom: 25px;
                    margin-top: 29px;
                    &.img_el{
                      display: unset;
                    }
                    &.img_el_active{
                      display: none;
                    }
                  }
                  .serve_article_item_label{
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 500;
                    font-size: 24px;
                    color: #333333;
                    line-height: 28px;
                    height: 50px;
                    margin-bottom: 10px;
                    text-align: center;
                  }
                  .serve_article_item_value{
                    flex: 1;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 18px;
                    color: #666666;
                    line-height: 22px;
                    text-align: justify;
                  }
                }
              }
            }
          }
        }
      }
      .news_content{
        min-width: 1200px;
        max-width: 1520px;
        width: calc(100vw - 10px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        margin-top: 120px;
        margin-bottom: 80px;
        padding: 0 4px;
        box-sizing: border-box;
        .news_topic{
          position: relative;
          margin-bottom: 70px;
          .news_content_sy{
            font-family: PingFang SC, PingFang SC;
            font-size: 42px;
            color: #E4E8F0;
            line-height: 46px;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.8;
            transform: translate(0,24px);
            letter-spacing: 2px;
          }
          .news_content_info{
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 42px;
            color: #222222;
            line-height: 46px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            display: flex;
            width: 100%;
            justify-content: space-between;
            .news_btn{
              width: 129px;
              height: 38px;
              background: #FFFFFF;
              border-radius: 100px 100px 100px 100px;
              border: 1px solid #0163B8;
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #0163B8;
              border-radius: 19px;
              cursor: pointer;
              i{
                margin-left: 10px;
              }
            }
          }
        } 
        .news_main{
          display: flex;
          .news_item_main{
            width: 100%;
            height: 100%;
            display: flex;
            .news_item{
              flex: 1;
              // overflow: hidden;
              margin-right: 28px;
              padding: 10px;
              box-sizing: border-box;
              overflow: hidden;
              &:last-child{
                margin-right: 0;
              }
              .news_item_content{
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                background: #FFFFFF;
                box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.1);
                .img_content{
                  height: 250px;
                  position: relative;
                  width: 100%;
                  img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                  .date_content{
                    position: absolute;
                    height: 34px;
                    width: 100%;
                    bottom: 0;
                    left: 0;
                    line-height: 34px;
                    padding: 0 20px;
                    box-sizing: border-box;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                  }
                }
                .article_content{
                  flex: 1;
                  overflow: hidden;
                  padding: 0 20px;
                  // padding-bottom: 50px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-evenly;
                  .article_topic{
                    overflow: hidden;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 600;
                    font-size: 18px;
                    color: #333333;
                    line-height: 21px;
                    text-overflow: ellipsis;
                    word-break: break-all;
                    white-space: nowrap;
                    width: 100%;
                    height: 21px;
                  }
                  .article_btn{
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 500;
                    font-size: 14px;
                    color: #333333;
                    line-height: 30px;
                    height: 30px;
                    cursor: pointer;
                    text-align: right;
                    i{
                      margin-left: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .link_content{
        min-width: 1200px;
        max-width: 1520px;
        width: calc(100vw - 10px);
        display: flex;
        margin: 0 auto;
        padding: 0 4px;
        box-sizing: border-box;
        height: 480px;
        margin-bottom: 80px;
        .link_item{
          flex: 1;
          overflow: hidden;
          background: url("./images/link@lxwm.png") 0 0 no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          &:first-child{
            flex: 1;
            background: url("./images/link@cpzx.png") 0 0 no-repeat;
            background-size: cover;
          }
          .link_topic{
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 42px;
            color: #FFFFFF;
            line-height: 46px;
            margin-bottom: 18px;
          }
          .link_topic_en{
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 20px;
            color: #E4E8F0;
            line-height: 23px;
            margin-bottom: 80px;
          }
          .link_btn{
            width: 167px;
            height: 43px;
            border-radius: 22px;
            border: 1px solid #FFFFFF;
            text-align: center;
            line-height: 43px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #FFFFFF;
            cursor: pointer;
            i{
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
</style>